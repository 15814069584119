// NavigationBar.js
import React from "react";
import "./NavBar.css"; // Import the CSS file for styling
import { Link } from "react-router-dom";
import logo from "../logo.png";

const NavBar = () => {
    return (
        <div className="navbar-wrap">
            <div className="navbar">
                <div className="logo-wrap">
                    <Link to="/" className="logo-wrap">
                        <div>
                            <img
                                src={logo} // Replace with the path to your logo
                                alt="Logo"
                                className="logo"
                            />
                        </div>
                        <div>
                            <span className="title-rando">Rando</span>
                        </div>
                    </Link>
                </div>
                <div className="button">Get App</div>
            </div>
        </div>
    );
};

export default NavBar;
