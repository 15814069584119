import React, { useState, useEffect } from "react";
import "./Main.css"; // Import the CSS file for styling
import NavBar from "./NavBar";
import Footer from "./Footer"; // Import the Footer component
import { useMediaQuery } from "react-responsive";
import one from "../Images/one.png";
import two from "../Images/two.png";
import three from "../Images/three.png";

const Main = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [one, two, three];
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [images.length]);

    return (
        <div className="main-container">
            <div className={isMobile ? "main-mobile" : "main"}>
                <div className="description">
                    <div className="title">Make social media fun again!</div>
                    <div className="point">🎯 Fun and exciting challenges</div>
                    <div className="point">🤝 Connect and play alongside friends</div>
                    <div className="point">📸 Random and stress-free posting</div>
                    <div className="desc">
                        Get a random photo challenge every day to share something wholesome and exciting with your
                        friends and the world.
                    </div>
                </div>
                <div className="image">
                    <img
                        src={images[currentImageIndex]}
                        alt="Image Slideshow"
                        className={isMobile ? "image-mobile" : "image-desk"}
                    />
                </div>
            </div>
            <Footer /> {/* Include the Footer component here */}
        </div>
    );
};

export default Main;
